import no_video from '../assets/img/no_video.jpg'
import no_face from '../assets/img/no_face.png'

export const PARSE_APP_ID = 'h1QhtsSjeoyQSa8RDQBDPvgbnI7Ix6nadHTsepwN';
export const PARSE_JS_KEY = 'Ci34OXCgbv7TuVOiUJFOmoSwULbC7JRnxvFaT1ZI';

// export const PARSE_SERVER_URL = 'https://patientparse.sabir.pro';
export const PARSE_SERVER_URL = 'https://parse.englishpatient.org/parse';

// export const TINY_MCE_API_KEY = 'qvx7hnoblixxgzko3ibp4hm12z5fyi9auhkkvwgjlps9xef8';
export const TINY_MCE_API_KEY = 'i4d4ht6d1jtd1xcdbax9jm55hymbqkuyluqlk6mmu1h87da6';
export const POLOTNO_KEY = 'C53CnSZB-T199GwDe7Ej';

export const COMPANY_NAME = 'English Patient';
export const FACELESS_AVATAR = no_face;

export const DICTIONARY_GROUP_ID = 'LmohVyRlIQ';
export const IDIOMS_GROUP_ID = 'lN6Ow0ZOBY';
export const ALPHABET_LETTERS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

// export const DEFAULT_VIDEO_PLACEHOLDER = 'https://www.englishpatient.org/english-patient-files/48fb7cda-91a5-4692-9dc2-0a73dd526a36.jpg';
export const DEFAULT_VIDEO_PLACEHOLDER = no_video;
export const PATIENT_ADMIN_ID = 'jnM2pCK62I';

export const EMBED_BASE = 'https://www.englishpatient.org/share/embed?url='
//
// export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || `https://api.entsu.ru`;
// export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || `https://api3.entsu.ru`;
// export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || `https://api2.entsu.ru`;

// export const API_ENDPOINT = `https://api.speakruss.ru`;
// export const API_ENDPOINT = `https://api.langplat.com`;
export const API_ENDPOINT = `https://api.kzlang.com`;
// export const API_ENDPOINT = `https://api.englishprouni.com`;
// export const API_ENDPOINT = `https://api.geo-school.org`;

// export const API_ENDPOINT = `https://api.cloud.ru-tsu.org`;
// export const API_ENDPOINT = `https://api-cn.cloud.ru-tsu.org`;
// export const API_ENDPOINT = `https://api.psyguide.online`;
// export const API_ENDPOINT = `https://api.hebrewlang.com`;
// export const API_ENDPOINT = `https://api.vietnam.englishpatient.online`;

export const USERS_AUDIO_UPLOAD_ENDPOINT = `https://files.entsu.ru/user-answer-audio/upload`;
export const IMAGE_UPLOAD_ENDPOINT = `https://files.entsu.ru/image/upload`;
export const VIDEO_UPLOAD_BASE = `https://files.entsu.ru`;


// export const OPENVIDU_SERVER_URL = 'https://kms.englishpatient.org';
// export const OPENVIDU_SERVER_SECRET = 'MY_SECRET';

export const OPENVIDU_SERVER_URL = 'https://de-fra-1.kms.sabir.pro';
export const OPENVIDU_SERVER_SECRET = 'MY_SECRET';

//classes
export const DEFAULT_CLASS_AVATAR_URL = 'https://www.englishpatient.org/storage/2860e99d-0698-4e2a-95b4-ee0d8f5c3239.jpg';
export const CLASSES_TYPES_OPTIONS = [
    {value: 'active', label: 'Студенты'},
    {value: 'archive', label: 'Архив'},
    {value: 'demo', label: 'Демо-классы'},
    {value: 'theme', label: 'Тематические'},
];

export const FIREBASE_CREDENTIALS = {
    apiKey: "AIzaSyCHj4gv-WTKfehdZq7S6LtVSPwFtajtX5g",
    authDomain: "english-patient.firebaseapp.com",
    databaseURL: "https://english-patient.firebaseio.com",
    projectId: "english-patient",
    storageBucket: "",
    messagingSenderId: "946238075806"
};
